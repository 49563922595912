<template>
  <div class="quota__progressbar">
    <div class="quota__progressbar-info">
      <div :style="`color:${getChildQuotaTitle.color}`">
        {{ getChildQuotaTitle.title }}
      </div>

      <div class="info__value">
        <span class="progress__unavailable">{{ unavailable }}</span>
        <span class="progress__divider">/</span>
        <span class="progress__booked">{{ booked }}</span>
        <span class="progress__divider">/</span>
        <span class="progress__total">{{ total }}</span>
      </div>
    </div>

    <div :class="['quota__progressbar-triple', heightClass]">
      <div
        v-if="booked"
        :style="`width:${displayPercent.booked}%`"
        class="triple__progress mod_booked"
      />

      <div
        v-if="unavailable"
        :style="`width:${displayPercent.unavailable}%`"
        class="triple__progress mod_unavailable"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TripleProgressBar',
  props: {
    quota: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    unavailable() {
      return this.quota.unavailable_count
    },
    booked() {
      return this.quota.count
    },
    total() {
      return this.quota.trucks_allowed_count
    },
    heightClass() {
      return this.isMobile ? 'mobileHeight' : 'desktopHeight'
    },
    displayPercent() {
      const width = Math.max(this.total, this.booked + this.unavailable)

      return {
        unavailable: (this.unavailable / width) * 100,
        booked: ((this.booked + this.unavailable) / width) * 100,
      }
    },
    getChildQuotaTitle() {
      if (this.booked === this.total && !this.unavailable) {
        return {
          title: 'Квота выполнена:',
          color: '#67C23A',
        }
      }
      if (this.booked + this.unavailable === this.total) {
        return {
          title: 'Квота закрыта:',
          color: '#000000',
        }
      }
      if (this.booked + this.unavailable > this.total) {
        return {
          title: 'Квота перевыполнена:',
          color: '#DB5D39',
        }
      }

      return {
        title: 'Тайм слоты:',
        color: '#606266',
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.progress__unavailable, .progress__divider
  color: $greyProgress
.progress__booked
  color: $color-blue-primary
.progress__total
  color: $color-orange-primary

.quota__progressbar-triple
  position: relative
  background: $greyBorder
  border-radius: 100px
  &.mobileHeight
    height: 8px
  &.desktopHeight
    height: 16px

  .triple__progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    border-radius: 100px
    &.mod_booked
      background: $color-blue-primary
      z-index: 2
    &.mod_unavailable
      background: $greyProgress
      z-index: 3
</style>
