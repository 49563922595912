var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "quota__progressbar" }, [
    _c("div", { staticClass: "quota__progressbar-info" }, [
      _c("div", { style: `color:${_vm.getChildQuotaTitle.color}` }, [
        _vm._v(" " + _vm._s(_vm.getChildQuotaTitle.title) + " "),
      ]),
      _c("div", { staticClass: "info__value" }, [
        _c("span", { staticClass: "progress__unavailable" }, [
          _vm._v(_vm._s(_vm.unavailable)),
        ]),
        _c("span", { staticClass: "progress__divider" }, [_vm._v("/")]),
        _c("span", { staticClass: "progress__booked" }, [
          _vm._v(_vm._s(_vm.booked)),
        ]),
        _c("span", { staticClass: "progress__divider" }, [_vm._v("/")]),
        _c("span", { staticClass: "progress__total" }, [
          _vm._v(_vm._s(_vm.total)),
        ]),
      ]),
    ]),
    _c("div", { class: ["quota__progressbar-triple", _vm.heightClass] }, [
      _vm.booked
        ? _c("div", {
            staticClass: "triple__progress mod_booked",
            style: `width:${_vm.displayPercent.booked}%`,
          })
        : _vm._e(),
      _vm.unavailable
        ? _c("div", {
            staticClass: "triple__progress mod_unavailable",
            style: `width:${_vm.displayPercent.unavailable}%`,
          })
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }